<template>
  <div class="conversions">
    <Loading :active="loading" :fullscreen="true" />

    <modal
      :active="createNewConversionActive"
      @dismiss="createNewConversionActive = false"
      class="create-conversion-modal form-control"
    >
      <h2>Create New Conversion</h2>
      <p>
        Submitting this form will trigger the full conversion process, including
        assigning a provider, sending any assigned emails, and forwarding the
        data to the Call Center.
      </p>
      <template v-if="newConversion">
        <div class="row">
          <div class="form-row">
            <label>First Name</label>
            <input v-model="newConversion.first_name" type="text" />
          </div>
          <div class="form-row">
            <label>Last Name</label>
            <input v-model="newConversion.last_name" type="text" />
          </div>
        </div>
        <div class="row">
          <div class="form-row">
            <label>Email</label>
            <input v-model="newConversion.email" type="email" />
          </div>
        </div>
        <div class="row">
          <div class="form-row">
            <label>Zip Code</label>
            <input v-model="newConversion.zip_code" type="text" />
          </div>
          <div class="form-row">
            <label>Telephone</label>
            <input v-model="newConversion.phone" type="tel" />
          </div>
        </div>

        <div class="form-row">
          <label>Message</label>
          <textarea v-model="newConversion.message"></textarea>
        </div>

        <input v-model="newConversion.campaign_id" type="hidden" />
      </template>

      <button class="button" @click="onCreateConversion">Create</button>
    </modal>

    <div class="hero hero--activate">
      <div class="container form-control">
        <h2>
          {{ heroLabel }}
        </h2>
        <div class="select-wrapper">
          <div class="select">
            <date-range-picker
              ref="picker"
              :locale-data="{ firstDay: 1, format: 'mm-dd-yyyy' }"
              :minDate="minDate"
              :autoApply="false"
              v-model="dateRange"
              @update="onUpdate"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </template>
              <template #date="data">
                <span class="small">{{ data.date | dateCell }}</span>
                <template
                  v-if="hasTrackingEvent(data.date, selectedCampaignId)"
                >
                  <span class="marker"> &bull; </span>
                  <span class="tooltip">{{
                    trackingEventLabel(data.date, selectedCampaignId)
                  }}</span>
                </template>
              </template>
            </date-range-picker>
          </div>
          <div class="select">
            <select v-model="selectedCampaignId" @change="onUpdate">
              <option value="">All Campaigns</option>
              <option
                v-for="campaign in campaignList"
                :key="`campaign-${campaign.id}`"
                :value="campaign.id"
              >
                {{ campaign.name }}
              </option>
            </select>
          </div>
          <div class="select">
            <select v-model="selectedProviderId" @change="onUpdate">
              <option value="">All Providers</option>
              <option
                v-for="provider in providerList"
                :key="`provider-${provider.id}`"
                :value="provider.id"
              >
                {{ provider.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="overview-wrapper">
        <div class="card-wrapper">
          <div class="card">
            <h2>Lead Pipeline Status</h2>
            <div class="lead-charts">
              <div class="doughnut">
                <div class="doughnut__wrapper">
                  <DoughnutChart
                    class="doughnut__chart"
                    :chartData="pieData"
                    :tooltips="{}"
                  ></DoughnutChart>
                  <div class="doughnut__total">
                    <strong>{{ $_conversions_conversions.length }}</strong>
                    Total Leads
                  </div>
                </div>
                <div class="doughnut__legend">
                  <div class="doughnut__legend-item">
                    <span
                      class="doughnut__bullet doughnut__bullet--qualified"
                    ></span>
                    <span>Qualified</span>
                    <span class="doughnut__legend-item-stat">{{
                      $_conversions_totalQualified
                    }}</span>
                  </div>

                  <div class="doughnut__legend-item">
                    <span
                      class="doughnut__bullet doughnut__bullet--unqualified"
                    ></span>
                    <span>Unqualified</span>
                    <span class="doughnut__legend-item-stat">{{
                      $_conversions_totalUnqualified
                    }}</span>
                  </div>

                  <div class="doughnut__legend-item">
                    <span
                      class="doughnut__bullet doughnut__bullet--invalid"
                    ></span>
                    <span>Invalid</span>
                    <span class="doughnut__legend-item-stat">{{
                      $_conversions_totalInvalid
                    }}</span>
                  </div>

                  <div class="doughnut__legend-item">
                    <span
                      class="doughnut__bullet doughnut__bullet--unknown"
                    ></span>
                    <span>Unknown</span>
                    <span class="doughnut__legend-item-stat">{{
                      $_conversions_totalUnknown
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="lead-metrics">
                <div class="lead-metrics__item">
                  <div class="lead-metrics__title">Connected</div>
                  <div class="lead-metrics__number">
                    {{ $_conversions_totalConnected }}
                  </div>
                  <div class="lead-metrics__percent">
                    {{ $_conversions_totalConnectedPercentage }}
                  </div>
                </div>

                <div class="lead-metrics__item">
                  <div class="lead-metrics__title">No Response</div>
                  <div class="lead-metrics__number">
                    {{ $_conversions_totalNoResponse }}
                  </div>
                  <div class="lead-metrics__percent">
                    {{ $_conversions_totalNoResponsePercentage }}
                  </div>
                </div>

                <div class="lead-metrics__item">
                  <div class="lead-metrics__title">Won</div>
                  <div class="lead-metrics__number">
                    {{ $_conversions_totalWon }}
                  </div>
                  <div class="lead-metrics__percent">
                    {{ $_conversions_totalWonPercentage }}
                  </div>
                </div>

                <div class="lead-metrics__item">
                  <div class="lead-metrics__title">Pending</div>
                  <div class="lead-metrics__number">
                    {{ $_conversions_totalPending }}
                  </div>
                  <div class="lead-metrics__percent">
                    {{ $_conversions_totalPendingPercentage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-wrapper">
          <div class="card form-control">
            <h2>Lead Pipeline Activity</h2>
            <LineChart
              :height="220"
              :chart-data="chartData"
              :scales="scales"
              :tooltips="tooltips"
            />
            <div class="graph-legend">
              <div class="graph-legend-item">
                <span
                  class="graph-legend-item-indicator"
                  :style="{ background: this.colors.lightBlueTint2 }"
                ></span>
                <span class="graph-legend-item-label">Total Leads</span>
              </div>
              <div class="graph-legend-item">
                <span
                  class="graph-legend-item-indicator"
                  :style="{ background: this.colors.lightBlueTint0 }"
                ></span>
                <span class="graph-legend-item-label">Connections</span>
              </div>
              <div class="graph-legend-item">
                <span
                  class="graph-legend-item-indicator"
                  :style="{ background: this.colors.green }"
                ></span>
                <span class="graph-legend-item-label">Leads Won</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--<h2>Conversion Predictions</h2>-->
      <div
        class="conversions__predictions"
        v-if="$_conversions_predictions_total"
      >
        <div class="card">
          <div class="conversions__predictions-wrapper">
            <div class="conversions__predictions-chart">
              <CircleChart
                :metric="$_conversions_predictions_total"
                :total="$_conversions_predictions_baseTotal"
              />
              <div class="conversions__predictions-chart-label">
                Will Connect
              </div>
            </div>
            <div class="conversions__predictions-segments">
              <div class="conversions__predictions-segment-label">
                Predicted Connections by Confidence Score
              </div>
              <SegmentChart
                :segments="$_conversions_predictions_confidenceIntervals"
              />
            </div>
            <div class="conversions__predictions-metrics">
              <div class="conversions__predictions-segment-label">
                Estimated Lead Results
              </div>
              <SimpleMetric
                :label="'Estimated Leads Qualified'"
                :measure="
                  $_conversions_predictions_estimatedLeadsQualifiedLabel
                "
                :context="
                  $_conversions_predictions_estimatedLeadsQualifiedPercentageLabel
                "
                :list="true"
              />
              <SimpleMetric
                :label="'Estimated Leads Activated'"
                :measure="
                  $_conversions_predictions_estimatedLeadsActivatedLabel
                "
                :context="
                  $_conversions_predictions_estimatedLeadsActivatedPercentageLabel
                "
                :list="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="conversions-title">
        <h2>Conversion Details</h2>

        <div class="filterbar">
          <span
            v-if="filter !== '' || filterDispositions.length || filterReviews"
            @click="onClearFilter"
            class="filterbar__clear"
            >Clear</span
          >
          <div class="filterbar__input">
            <input
              type="text"
              v-model="filter"
              v-on:keyup.enter="onFilterConversions"
              placeholder="Search leads"
            />
          </div>
          <div
            class="filterbar__select"
            v-click-outside="onCloseDispositionsFilterbar"
          >
            <div @click="toggleDispositions" class="filterbar__label">
              Dispositions
              <Pill :count="filterDispositions.length" />
            </div>
            <div v-if="dispositionsActive" class="filterbar__checkboxes">
              <div>
                <input
                  type="checkbox"
                  value="Pending"
                  v-model="filterDispositions"
                />
                Pending
              </div>
              <div v-for="(event, index) in events" :key="`event-${index}`">
                <input
                  type="checkbox"
                  :value="event.external_name"
                  v-model="filterDispositions"
                />
                {{ event.external_name }}
              </div>
            </div>
          </div>
          <div class="filterbar__select form-control">
            <select v-model="filterReviews">
              <option :value="null">All Reviews</option>
              <option value="complete">Reviews Complete</option>
              <option value="pending">Reviews Pending</option>
            </select>
          </div>
          <div class="filterbar__submit">
            <button @click="onFilterConversions">Filter</button>
          </div>
        </div>

        <div class="list-actions">
          <button
            v-if="selectedCampaignId"
            class="button"
            @click="onNewConversion"
          >
            New Conversion
          </button>
          <button class="button" @click="onDownloadList">Download List</button>
        </div>
      </div>

      <div class="card-wrapper">
        <div class="card form-control">
          <template v-if="$_conversions_conversions.length">
            <table class="styled-table styled-table--with-footer">
              <tr>
                <th>Type</th>
                <th @click="sortConversions('last_name')">Name</th>
                <th @click="sortConversions('last_action_date')">
                  Last Active
                </th>
                <th @click="sortConversions('disposition')">Disposition</th>
                <th @click="sortConversions('nurse_id')">Nurse Name</th>
                <th
                  class="icon-cell"
                  @click="sortConversions('need_review_count')"
                >
                  Review
                </th>
              </tr>
              <tr
                v-for="conversion in filteredConversions"
                :key="`conversion-${conversion.id}`"
                @click="onSelectConversion(conversion.id)"
              >
                <td>
                  <CircleIcon
                    :icon="'smartphone'"
                    v-if="conversion.type === 'lead_created_phone'"
                  />
                  <CircleIcon :icon="'envelope'" :color="'blue'" v-else />
                </td>
                <td>
                  <strong>{{
                    $_conversions_getFullName(conversion)
                      | valueOrDash
                      | truncate(20)
                  }}</strong>
                  Created: {{ conversion.created_at | date }}
                </td>
                <td>
                  {{ conversion.last_action_date | timeDifferenceInWords }}
                </td>
                <td>{{ conversion.disposition | truncate(25) }}</td>
                <td>
                  {{
                    $_nurses_getNurseNameById(conversion.nurse_id)
                      | valueOrDash
                      | truncate(20)
                  }}
                </td>
                <td class="icon-cell">
                  <template v-if="conversion.need_review">
                    <BaseSVG
                      v-if="conversion.need_review_count === 0"
                      :src="require(`@/assets/check-mark-icon.svg`)"
                    />
                    <Pill
                      v-else
                      :count="conversion.need_review_count"
                      :alert="true"
                      :label="true"
                    />
                  </template>
                </td>
              </tr>
            </table>

            <Pagination
              :items="conversions"
              :pageLength="pageLength"
              @paginate="onPaginate"
            ></Pagination>
          </template>
          <template v-else>
            <p class="alert">No Conversions</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";
import numeral from "numeral";
import Loading from "@/components/Loading";
import { conversions } from "@/mixins/conversions";
import { colors } from "@/mixins/colors";
import Pagination from "@/components/Pagination";
import DoughnutChart from "@/components/charts/DoughnutChart";
import LineChart from "@/components/charts/LineChart";
import dateFormat from "date-fns/format";
import Modal from "@/components/Modal";
import DateRangePicker from "vue2-daterange-picker";
import { events } from "@/mixins/events";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Papa from "papaparse";
import FileDownload from "js-file-download";
import { nurses } from "@/mixins/nurses";
import Pill from "@/components/Pill";
import { trackingEvents } from "@/mixins/trackingEvents";
import CircleChart from "@/components/charts/CircleChart";
import SegmentChart from "@/components/SegmentChart";
import SimpleMetric from "@/components/SimpleMetric";
import CircleIcon from "@/components/CircleIcon";

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const today = new Date();
const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

export default {
  name: "Conversions",
  mixins: [conversions, colors, events, nurses, trackingEvents],
  components: {
    BaseSVG,
    Loading,
    Pagination,
    DoughnutChart,
    LineChart,
    Modal,
    DateRangePicker,
    Pill,
    CircleChart,
    SegmentChart,
    SimpleMetric,
    CircleIcon,
  },
  data() {
    return {
      createNewConversionActive: false,
      minDate: "08-31-2021",
      newConversion: null,
      pageLength: 20,
      sidebarActive: false,
      selectedConversion: null,
      selectedCampaignId: "",
      selectedProviderId: "",
      dateRange: {
        startDate: oneWeekAgo,
        endDate: today,
      },
      conversions: [],
      filteredConversions: [],
      filter: "",
      filterDispositions: [],
      dispositionsActive: false,
      reviewStatusActive: false,
      filterReviews: null,
      sortDirection: "asc",
      sortFlag: null,
    };
  },
  filters: {},
  computed: {
    loading() {
      return this.$store.getters["conversions/loading"];
    },
    heroLabel() {
      if (!this.$route.params.campaign || !this.$_conversions_campaign) {
        return "Conversions";
      }

      return this.$_conversions_campaign.name;
    },
    campaignList() {
      return this.$store.getters["conversions/campaigns"];
    },
    providerList() {
      return this.$store.getters["conversions/providers"];
    },
    dateRangeToNumber() {
      let num = 0;
      switch (this.dateRange) {
        case "year":
          num = 365;
          break;
        case "month":
          num = 30;
          break;
        case "week":
          num = 7;
          break;
        case "day":
          num = 2;
          break;
      }

      return num;
    },
    dates() {
      const startDate = new Date(this.dateRange.startDate);
      const endDate = new Date(this.dateRange.endDate);

      var results = new Array();
      var currentDate = startDate;
      while (currentDate <= endDate) {
        results.push(new Date(currentDate));
        currentDate = currentDate.addDays(1);
      }

      return results;
    },
    datesLabels() {
      let result = [];
      this.dates.forEach((date) => {
        result.push(`${date.getMonth() + 1}/${date.getDate()}`);
      });

      return result;
    },
    engagementsByDate() {
      return this.conversionsByDate(this.$_conversions_engagements);
    },
    activationsByDate() {
      return this.conversionsByDate(
        this.$_conversions_activations,
        "last_action_date"
      );
    },
    connectionsByDate() {
      return this.conversionsByDate(this.$_conversions_connections);
    },
    scales() {
      return {
        yAxes: [
          {
            ticks: {
              suggestedMin: 0,
              callback: function (value) {
                return numeral(value).format("0,0");
              },
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              callback: (value) => {
                if (this.dateRange === "year") {
                  return dateFormat(value, "MMM");
                }

                return `${value.getMonth() + 1}/${value.getDate()}`;
              },
            },
          },
        ],
      };
    },
    tooltips() {
      return {
        callbacks: {
          label: function (tooltipItem) {
            return numeral(tooltipItem.value).format("0,0");
          },
          title: function (tooltipItem) {
            let d = new Date(tooltipItem[0].xLabel);
            return dateFormat(d, "M/d/y");
          },
        },
      };
    },
    chartData() {
      return {
        labels: this.dates,
        datasets: [
          {
            backgroundColor: this.colors.lightBlueTint0,
            borderColor: this.colors.lightBlueTint0,
            data: this.connectionsByDate,
            fill: false,
            lineTension: 0.5,
          },
          {
            backgroundColor: this.colors.green,
            borderColor: this.colors.green,
            data: this.activationsByDate,
            fill: false,
            lineTension: 0.5,
          },
          {
            backgroundColor: this.colors.lightBlueTint2,
            borderColor: this.colors.lightBlueTint2,
            data: this.engagementsByDate,
            fill: false,
            lineTension: 0.5,
          },
        ],
      };
    },
    pieData: function () {
      return {
        labels: ["Qualified", "Unqualified", "Invalid", "Unknown"],
        datasets: [
          {
            data: [
              this.$_conversions_totalQualified,
              this.$_conversions_totalUnqualified,
              this.$_conversions_totalInvalid,
              this.$_conversions_totalUnknown,
            ],
            backgroundColor: [
              this.colors.lightBlueTint0,
              this.colors.lightBlueTint1,
              this.colors.lightBlueTint2,
              this.colors.lightBlueTint3,
            ],
            borderWidth: 0,
          },
        ],
      };
    },
  },
  methods: {
    routeIsUpToDate(query) {
      const keys1 = Object.keys(query);
      const keys2 = Object.keys(this.$route.query);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (query[key] !== this.$route.query[key]) {
          return false;
        }
      }

      return true;
    },
    onUpdate() {
      const dateRangeString = JSON.stringify(this.dateRange);

      this.$_conversions_getConversions(
        this.selectedCampaignId,
        this.selectedProviderId,
        dateRangeString
      ).then(() => {
        this.conversions = this.$_conversions_conversions;
        this.filter = "";
        this.filterDispositions = [];
      });

      const query = {
        campaign: this.selectedCampaignId,
        provider: this.selectedProviderId,
        range: dateRangeString,
      };

      if (!this.routeIsUpToDate(query)) {
        this.$router.push({
          path: "conversions",
          query: query,
        });
      }
    },
    onSelectConversion(conversionId) {
      let routeData = this.$router.resolve({
        name: "conversion",
        params: { id: conversionId },
      });
      window.open(routeData.href, "_blank");
    },
    getReportingData() {
      this.$store.dispatch("conversions/getReportingData");
    },
    onDownloadList() {
      const csv = Papa.unparse(this.formatDownloadList());
      FileDownload(csv, `sharemd-connect-conversions.csv`);
    },
    formatDownloadList() {
      const typeFilter = this.$options.filters.typeFilter;
      const $_nurses_getNurseNameById = this.$_nurses_getNurseNameById;
      const conversions = this.conversions.map(function (conversion) {
        return {
          id: conversion.id,
          type: typeFilter(conversion.type),
          first_name: conversion.first_name,
          last_name: conversion.last_name,
          email: conversion.email,
          phone: conversion.phone,
          city: conversion.city,
          state: conversion.state,
          zip_code: conversion.zip,
          campaign: conversion.campaign_name,
          last_disposition: conversion.disposition,
          disposition_count: conversion.actions.length,
          nurse: $_nurses_getNurseNameById(conversion.nurse_id),
          created_at: dateFormat(
            new Date(conversion.created_at),
            "MMM d, Y - haaa"
          ),
          last_action_date: dateFormat(
            new Date(conversion.last_action_date),
            "MMM d, Y - haaa"
          ),
        };
      });
      return conversions;
    },
    onNewConversion() {
      this.newConversion = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        zip_code: "",
        message: "",
        campaign_id: this.selectedCampaignId,
      };
      this.createNewConversionActive = true;
    },
    onCreateConversion() {
      this.$store
        .dispatch("conversions/addConversion", this.newConversion)
        .then(() => {
          this.createNewConversionActive = false;
          this.onUpdate();
        });
    },
    onPaginate(items) {
      this.filteredConversions = items;
    },
    onClearFilter() {
      this.filter = "";
      this.filterDispositions = [];
      this.filterReviews = null;

      this.onFilterConversions();
    },
    onFilterConversions() {
      this.dispositionsActive = false;
      this.conversions = this.$_conversions_conversions;

      if (this.filterReviews) {
        this.conversions = this.conversions.filter((conversion) => {
          if (this.filterReviews === "pending") {
            return conversion.need_review_count > 0;
          } else if (this.filterReviews === "complete") {
            return conversion.need_review_count === 0;
          }

          return true;
        });
      }

      if (this.filter !== "") {
        const filter = this.filter.toLowerCase();

        this.conversions = this.conversions.filter((conversion) => {
          if (conversion.first_name === null) {
            conversion.first_name = "";
            conversion.last_name = "";
          }
          const result =
            conversion.first_name.toLowerCase().indexOf(filter) !== -1 ||
            conversion.last_name.toLowerCase().indexOf(filter) !== -1 ||
            this.$_conversions_getFullName(conversion)
              .toLowerCase()
              .indexOf(filter) !== -1 ||
            conversion.nurse_id.indexOf(filter) !== -1 ||
            this.$options.filters
              .typeFilter(conversion.type)
              .toLowerCase()
              .indexOf(filter) !== -1;

          return result;
        });
      }

      if (this.filterDispositions.length) {
        this.conversions = this.conversions.filter((conversion) => {
          return this.filterDispositions.indexOf(conversion.disposition) !== -1;
        });
      }
    },
    toggleDispositions() {
      this.dispositionsActive = !this.dispositionsActive;
    },
    sortConversions(flag) {
      if (this.sortFlag === flag) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortDirection = "asc";
      }
      const sortDirection = this.sortDirection;

      function compare(a, b) {
        // Use toUpperCase() to ignore character casing

        let bandA = a[flag];
        let bandB = b[flag];

        if (typeof a[flag] === "string") {
          bandA = a[flag].toUpperCase();
          bandB = b[flag].toUpperCase();
        }

        let comparison = 0;
        if (bandA > bandB) {
          comparison = sortDirection === "asc" ? 1 : -1;
        } else if (bandA < bandB) {
          comparison = sortDirection === "asc" ? -1 : 1;
        }

        return comparison;
      }

      this.conversions.sort(compare);
      this.sortFlag = flag;
    },
    conversionsByDate(conversions, date_type) {
      if (!date_type) {
        date_type = "created_at";
      }
      let result = [];
      this.dates.forEach((date) => {
        date.setHours(0, 0, 0, 0);
        let sum = conversions.reduce((total, engagement) => {
          let d = new Date(engagement[date_type]);
          d.setHours(0, 0, 0, 0);
          return total + (date.getTime() === d.getTime() ? 1 : 0);
        }, 0);

        result.push(sum);
      });

      return result;
    },
    onCloseDispositionsFilterbar() {
      this.dispositionsActive = false;
    },
  },
  mounted() {
    this.getReportingData();

    if (
      typeof this.$route.query.campaign !== "undefined" &&
      this.$route.query.campaign !== ""
    ) {
      this.selectedCampaignId = this.$route.query.campaign;
    }

    if (
      typeof this.$route.query.provider !== "undefined" &&
      this.$route.query.provider !== ""
    ) {
      this.selectedProviderId = this.$route.query.provider;
    }

    if (typeof this.$route.query.range !== "undefined") {
      const range = JSON.parse(this.$route.query.range);
      this.dateRange = {
        endDate: new Date(range.endDate),
        startDate: new Date(range.startDate),
      };
    }

    this.onUpdate();
  },
  watch: {
    $route() {
      if (typeof this.$route.query.campaign === "undefined") {
        this.getCampaigns();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";
@import "@/scss/forms";
@import "@/scss/table";
@import "@/scss/hero";
@import "@/scss/filter";
@import "@/scss/date_range";

.lead-charts {
  display: flex;
}

.lead-metrics {
  flex-grow: 1;

  &__item {
    align-items: center;
    border-bottom: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    padding: 8px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    flex-grow: 1;
  }

  &__number {
    font-size: 32px;
    font-weight: 600;
    text-align: right;
    width: 75px;
  }

  &__percent {
    color: $light-gray;
    font-size: 12px;
    text-align: right;
    width: 40px;
  }
}

.doughnut {
  margin: 0 3em 2em 0;
  width: 205px;

  &__wrapper {
    position: relative;
  }

  &__chart {
    position: relative;
    z-index: 1;
  }

  &__total {
    font-size: 16px;
    left: 50%;
    line-height: 1.2;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

    strong {
      display: block;
      font-size: 36px;
    }
  }

  &__legend {
    margin: 1rem auto 0 0;
  }

  &__legend-item {
    font-size: 14px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    &-stat {
      margin-left: auto;
      font-weight: 700;
    }
  }

  &__bullet {
    display: inline-block;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
    width: 8px;

    &--qualified {
      background-color: $light-blue-tint0;
    }

    &--unqualified {
      background-color: $light-blue-tint1;
    }

    &--pending {
      background-color: $orange;
    }

    &--won {
      background-color: $green;
    }

    &--invalid {
      background-color: $light-blue-tint2;
    }

    &--unknown {
      background-color: $light-blue-tint3;
    }
  }
}

.conversions .hero {
  .select {
    max-width: 200px;
  }
}

.create-conversion-modal {
  p {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 2em;
  }
}

.conversions-title {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  position: relative;
  z-index: 9;

  h2 {
    margin-bottom: 0 !important;
  }

  .list-actions {
    button + button {
      margin-left: 8px;
    }
  }
}

.conversions {
  .overview-wrapper {
    margin-bottom: 2rem;
    display: flex;
    position: relative;

    .card-wrapper:first-of-type {
      width: 50%;
      padding-right: 1rem;
    }

    .card-wrapper:last-of-type {
      width: 50%;
      padding-left: 0.5rem;

      .card {
        height: 100%;
      }
    }

    .graph-legend {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      &-item {
        display: flex;
        align-items: center;
        margin: 0 1rem;

        &-indicator {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
        }

        &-label {
          font-size: 0.75rem;
          color: $medium-gray;
          margin-left: 0.25rem;
        }
      }

      &.graph-legend-top {
        margin-top: 0;
        margin-bottom: 1rem;
        justify-content: flex-start;

        .graph-legend-item {
          margin: 0;

          &-label {
            margin-left: 0;
          }
        }
      }
    }
  }

  .hero {
    .container {
      .select-wrapper {
        margin-left: auto;
        display: flex;

        .select {
          margin-left: 32px;
          select {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  li {
    margin: 8px 0;
  }

  .card {
    position: relative;

    .select-wrapper {
      position: absolute;
      top: 1.25rem;
      right: 2rem;

      .select {
        display: inline-block;
        margin-right: 1rem;
        min-width: 128px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      & + .styled-table {
        margin-top: 48px;
      }
    }
  }

  .styled-table {
    tr {
      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
        background-color: #f6f6f6;

        &:first-child {
          background-color: #fff;
        }
      }
    }

    td,
    th {
      &:first-of-type {
        width: 48px !important;
        padding-right: 0;
      }

      &:last-of-type {
        width: 140px;
      }
    }

    td {
      padding-bottom: 10px !important;
      padding-top: 10px !important;
      vertical-align: middle;

      &:first-of-type {
        svg {
          fill: $medium-gray;
        }
      }

      strong {
        color: $light-blue;
        display: block;
        font-size: 16px;
        margin-bottom: 4px;
      }
    }

    span {
      cursor: pointer;
    }

    .icon-cell {
      max-width: 128px;
      text-align: center;

      svg {
        fill: $green;
      }
    }
  }

  .kpi-wrapper {
    display: flex;
    margin: 0 -8px;
    margin-bottom: 2rem;

    .card {
      margin: 0 8px;
      width: 25%;
    }
  }

  &__predictions {
    margin-bottom: 2rem;

    &-wrapper {
      display: flex;
      align-items: center;

      h2 {
        margin-bottom: 0 !important;
      }
    }

    &-metrics {
      margin-left: auto;
      border-left: solid 2px $fade-gray;
      flex-grow: 1;
      padding-left: 2rem;
    }

    &-segments {
      padding-right: 2rem;
    }

    &-chart {
      width: 80px;
      margin-right: 2rem;

      &-label {
        font-size: 13px;
        color: $medium-gray;
        text-align: center;
        margin-top: 0.5rem;
        font-weight: 600;
      }
    }

    &-segment-label {
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.form-row {
  flex-grow: 1;
  margin-bottom: 1em;

  label {
    display: block;
    margin-bottom: 6px;
  }

  textarea {
    height: 44px;
  }
}
</style>